import React from 'react'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'

import Hero from '../sections/Hero/Hero'
import Showcase from '../sections/Showcase/Showcase'
import Highlights from '../sections/Highlights/Highlights'
import Connect from '../sections/Connect/Connect'
import Try from '../sections/Try/Try'
import RequestDemo from '../sections/RequestDemo/RequestDemo'

const Engineer = () => {
  return (
    <Layout pageName="engineer">
      <Seo />
      <Container>
        <Hero
          heading="Simplify engineering workflows"
          subheading="Engineering workflows with Ally, from idea to release and beyond."
          group="Engineers"
          features={[
            'Alerts',
            'Scheduled reports',
            'Dashboards',
            'Notifications',
            'Surveys',
          ]}
          imageName="hero-green.png"
          isSolutions
        />

        <Showcase imageName="engineers-showcase" />

        <RequestDemo />

        <Highlights
          items={[
            {
              heading: 'Ally provides visibility from idea to production',
              subheading:
                'Fully-customizable dashboards enable you to diagnose and act on team issues',
              imageName: 'engineers-highlights-1.png'
            },
            {
              heading: 'Keep an eye on the progress',
              subheading:
                'Scheduled reports delivered to your chat platforms. Help your team to move work forward.',
              imageName: 'engineers-highlights-2.png'
            },
            {
              heading: 'Alerts help you stay on top of your day',
              subheading:
                'Receive alerts in Slack or Microsoft Teams when something has or has not happened.',
              imageName: 'engineers-highlights-3.png'
            },
            {
              heading: 'Do not miss anything important with notifications',
              subheading:
                'Extended filters and configurations enable you reduce noise and do not miss important events. Get notifications delivered to your chat platform.',
              imageName: 'engineers-highlights-4.png'
            },
            {
              heading: 'Combine qualitative and quantitive data',
              subheading:
                'Run surveys with your team and combine results with events and data from connected tools like GitHub, Jira or Slack.',
              imageName: 'daily-team-survey.png',
              imageMobileName: 'daily-team-survey.png'
            }
          ]}
          startsLeft
        />

        <Connect />

        <Try isSolutions />
      </Container>
    </Layout>
  )
}

export default Engineer
